// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs"
import pkg from "./package.json"

/* Only env variables prefoxed with NEXT_PUBLIC_ are avaiable in this file */
const SENTRY_DSN =
  process.env.SENTRY_DSN_FRONTEND || process.env.NEXT_PUBLIC_SENTRY_DSN

Sentry.init({
  dsn: SENTRY_DSN,
  enabled: process.env.NEXT_PUBLIC_FRONTEND_ENV !== "development",
  tracesSampleRate: 1.0,
  debug: process.env.NEXT_PUBLIC_FRONTEND_ENV === "development",
  release: pkg.version,
  dist: pkg.version,
  environment: process.env.NEXT_PUBLIC_FRONTEND_ENV,
})
