import React from "react"
import CircularProgress from "@mui/material/CircularProgress"
import theme from "./theme"

function FacebookCircularProgress(props) {
  return (
    <div
      style={{
        zIndex: 10000,
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%,-50%)",
      }}
    >
      <CircularProgress
        variant="determinate"
        sx={{ color: theme.palette.primary.main }}
        size={props.size}
        thickness={props.thickness}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: props.aucolor,
          animationDuration: "550ms",
          position: "absolute",
          left: 0,
          strokeLinecap: "round",
        }}
        size={props.size}
        thickness={props.thickness}
        {...props}
      />
    </div>
  )
}

export default function BrandProgress({
  size = 20,
  thickness = 5,
  aucolor = "#1a90ff",
}) {
  return (
    <div style={{ flexGrow: 1 }}>
      <FacebookCircularProgress
        size={size}
        aucolor={aucolor}
        thickness={thickness}
      />
    </div>
  )
}
