import { useRouter } from "next/router"

export const formatTime = (seconds) => {
  if (seconds < 60) {
    let time = (seconds / 100).toFixed(2).split(".")
    return `00:00:${time[1]}`
  } else if (seconds >= 60 && seconds < 3600) {
    let minutes = Math.floor(seconds / 60)
    let secondsRemaing = Math.floor(seconds - minutes * 60)
    let showSeconds = (secondsRemaing / 100).toFixed(2).split(".")
    let formattedMinutes = ""
    if (minutes < 10) formattedMinutes = `0${minutes}`
    else formattedMinutes = `${minutes}`
    return `00:${formattedMinutes}:${showSeconds[1]}`
  } else if (seconds >= 3600) {
    let hours = (seconds / 3600).toFixed(0)
    let remainingTime = seconds - hours * 3600
    let minutes = Math.floor(remainingTime / 60)
    let secondsRemaing = Math.floor(remainingTime - minutes * 60)
    let showSeconds = (secondsRemaing / 100).toFixed(2).split(".")
    let formattedTime = ""
    if (hours < 10) formattedTime += `0${hours}:`
    else formattedTime += `${hours}:`
    if (minutes < 10) formattedTime += `0${minutes}:`
    else formattedTime += `${minutes}:`
    if (showSeconds[1] < 10) formattedTime += `0${showSeconds[1]}`
    else formattedTime += `${showSeconds[1]}`
    return formattedTime
  }
}

export const trimText = (text, threshold) => {
  if (text.length <= threshold) return text
  return text.substr(0, threshold).concat("...")
}

export const bytesToGB = (bytes) => {
  return bytes / 1073741824
}

export const formatFileSize = (bytes) => {
  const GB = bytesToGB(bytes)
  if (GB < 0.009) return roundAccurately(GB, 4) + " GB"
  else return roundAccurately(GB, 3) + " GB"
}

export const roundAccurately = (number, decimalPlaces) => {
  return Number(Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces)
}
