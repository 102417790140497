import { useRouter } from "next/router"
import { locales } from "../locals"
import Breadcrumbs from "nextjs-breadcrumbs"
import { useTranslation } from "next-i18next"
import { trimText } from "../utils/format"
import Box from "@mui/material/Box"
import { Button, IconButton, Link, Typography } from "@mui/material"
import { usePlayerContext } from "../context/PlayerContext"
import { useRef, useEffect } from "react"

const HeaderNavigation = ({ shouldHideImage = false }) => {
  const router = useRouter()
  const { isPlaying, togglePlayingState } = usePlayerContext()
  const breadcrumbsRef = useRef(null)

  useEffect(() => {
    if (breadcrumbsRef && breadcrumbsRef.current) {
      breadcrumbsRef.current.addEventListener("click", (e) => {
        togglePlayingState(false)
      })
    }
  }, [])

  const { t } = useTranslation()

  return (
    <Box
      ref={breadcrumbsRef}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {router.pathname.startsWith("/account") && (
        <Button
          variant="text"
          sx={{
            marginRight: 4,
            textTransform: "capitalize",
          }}
          onClick={() => router.push("/archives")}
        >
          <Typography
            color="#000"
            fontWeight={600}
            variant="body2"
            fontSize={12}
            fontFamily="Libre Franklin"
          >
            {t("archives")}
          </Typography>
        </Button>
      )}

      <Breadcrumbs
        inactiveItemClassName={"au_breadcrumbs_item"}
        activeItemClassName={"au_breadcrumbs_item_action"}
        containerClassName={"au_breadcrumbs"}
        transformLabel={(title) => {
          if (title === "search" || title === "archivsearch") {
            return t("search")
          }

          if (title === "archives") {
            return t("archives")
          }

          if (title === "account") {
            return t("account_settings")
          }

          return trimText(decodeURI(title), 15)
        }}
        useDefaultStyle={true}
        omitRootLabel
        omitIndexList={[1, 3, 5]}
      />
    </Box>
  )
}

export default HeaderNavigation
