import { AUSearchInArchiveResultsItemProps } from "../SearchInArchive/AUSearchInArchiveResultsItem"

export enum WorkerStatus {
  SUCCESS = "SUCCESS",
  PENDING = "PENDING",
  STARTED = "STARTED",
  RETRY = "RETRY",
  FAILURE = "FAILURE",
}

export enum Languages {
  DE = "de-DE",
  ENUS = "en-US",
  ENGB = "en-GB",
  ES = "es-ES",
  ESUS = "es-US",
}

export enum SpeakerDiarization {
  MONOLOG = 0,
  DIALOG = 2,
  GROUP_TALK = 5,
}

export interface AUUser {
  email: string
  isActive: boolean
  role: UserRoles
  fullName: string
  id: number
  isVerified: boolean
  newsletter: boolean
  language: Languages
}

export enum UserRoles {
  SUPER_USER = "superuser",
  PROJECT_LEAD = "projectlead",
  EDITOR = "editor",
  PUBLIC_USER = "publicuser",
}

export interface AUSubscription {
  type: AUSubscriptionPlanTypes
  period: string
  subscriptionAvaiableMinutes: number
  extraAvaiableMinutes: number
  usedMinutes: number
  paid: boolean
  status: SubscriptionStatus
  renewalDate: string
}

export enum SubscriptionStatus {
  SUBSCRIBED = "subscribed",
  PAUSED = "paused",
  CANCELLED = "canceled",
}

export enum AUSubscriptionPlanTypes {
  FREE_TRIAL,
  PRO_PLAN_MONTHLY,
  PRO_PLAN_YEARLY,
  EXTRA_HOUR,
  INSTITUTION_PLAN,
}

export interface AUArchive {
  id: number
  name: string
  contacts: AUContacts[]
}

export interface AUContacts {
  id: number
  name: string
  email: string
}

export interface AUCollection {
  id: number
  name: string
  isPublic: boolean
  language: Languages
}

export const AUSubscriptionPrices = [0.0, 22.0, 240.0, 8.0]

export interface AUArchive {
  id: number
  name: string
  contacts: AUContacts[]
}

export interface AUContacts {
  id: number
  name: string
  email: string
}

export interface AUCollection {
  id: number
  name: string
  isPublic: boolean
}

export enum AUBackendSubscriptionPlanTypes {
  FREE_TRIAL = "free",
  PRO_PLAN_MONTHLY = "pro_month",
  PRO_PLAN_YEARLY = "pro_year",
  EXTRA_HOUR = "extra_hour",
  INSTITUTION_PLAN = "institution",
}

export interface AUCurrentSpeakerTextSelection {
  startTime: number
  endTime: number
  speakerID: number
}

export interface AUCurrentTextSelection {
  paragraphID: number
  startTime: number
  endTime: number
  speakerID: number
  text: string
}

export interface AUTranscriptContextModel {
  currentGridRowID: number
  currentSpeakerID?: string
  speakers: AUSpeaker[]
  textUpdate?: AUCurrentTextSelection
  speakerTextUpdate?: AUCurrentSpeakerTextSelection
  speakerNameUpdate?: string
  speakerNameCreate?: string
  directTextMenuAccess?: boolean
  directTextMenuSpeakersAccess?: boolean
  shadowSelectionModeWithSelectionWord?: string | null
}

export const defaultTranscriptState: AUTranscriptContextModel = {
  currentGridRowID: 0,
  currentSpeakerID: null,
  speakers: [],
  textUpdate: null,
  speakerTextUpdate: null,
  directTextMenuAccess: false,
  directTextMenuSpeakersAccess: false,
  shadowSelectionModeWithSelectionWord: null,
}

export interface AurekaError {
  title: string
  message: string
}

export interface AUTranscript {
  speakers: AUSpeaker[]
  paragraphs: AUParagraph[]
}

export interface AUDocument {
  id: number
  collectionID: number
  title: string
  streamURL: string
  peaksURL?: string
  isAudio: boolean
  transcript: AUTranscript
  annotations: AUAnnotation[]
  metadataItems: AUMetadataItem[]
  currentAnnotation: AUAnnotation
  exports: AUExport[]
}

export interface AUSentenceSearchResult {
  id: number
  words: AUWord[]
  paragraphID: number
  paragraphOrder: number
}

export interface AUTranscriptSearchResults {
  annotations: AUAnnotation[]
  metadataItems: AUMetadataItem[]
  textItems: AUSentenceSearchResult[]
  activeTextItem: AUSentenceSearchResult
  searchQuery: string
}

export interface AUAnnotation {
  id?: number
  timecode: number
  key: string
  value: string
  creator?: string
  editor?: string
}

export interface AUCollectionAnnotation {
  name: string
  frequency: number
  recordings: AUChartRecording[]
}

export interface AUMetadataItem {
  id?: number | string
  meta_key: string
  meta_value: string
}

export interface QuickSearchToolbarProps {
  clearSearch: () => void
  onChange: () => void
  value: string
  placeholder: string
  filteredResultsCount: number
  onAdd?: () => void
}

//#region Context Related Models

export interface AUDocumentContextNoteModel {
  id?: number | null
  timecode?: number | null
  key: string
  value: string
}

export interface AUDocumentContextModel {
  transcriptSearchQuery?: string | null
  documentID?: number | null
  collectionID?: number | null
  activeNote?: AUDocumentContextNoteModel | null
}

export const defaultDocumentNoteState: AUDocumentContextNoteModel = {
  id: null,
  timecode: null,
  key: "",
  value: "",
}

export const defaultDocumentState: AUDocumentContextModel = {
  transcriptSearchQuery: null,
  documentID: null,
  collectionID: null,
  activeNote: defaultDocumentNoteState,
}

//#region Models' Extensions

declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    // extends React's HTMLAttributes
    highlighted?: boolean
    starttime?: number
    paragraphid?: number
    endtime?: number
    speakerid?: number
  }
}
//#endregion

//#region TableView Models

export type ActiveTextPopup = "speaker" | "text" | "delete" | "menu"

export type Order = "asc" | "desc"

export interface EnhancedTableToolbarProps {
  numSelected: number
}

export interface HeadCell {
  disablePadding: boolean
  id: any
  label: string
  numeric: boolean
}

export interface EnhancedTableProps {
  data: Array<HeadCell>
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof AUListItem
  ) => void
  order: Order
  orderBy: string
  rowCount: number
}

//#endregion

//#region Data Display Models

export interface AUSpeaker {
  id: number
  name: string
}

export interface AUWord {
  textIndex?: any
  id: number
  speakerID?: number
  isLowQuality: boolean
  isPunctuation: boolean
  startTime: number
  endTime: number
  word: string
  edited: boolean
  type: string
}

export interface AUSentenceNoEdit {
  id: number
  words: AUWord[]
}

export interface AUSentence {
  id: number
  speakerName: string
  speakerID: number
  wordsText: string
  words: AUWord[]
}

export interface AUParagraph {
  id: number
  orderNum: number
  speakerName: string
  speakerID: number
  wordsText: string
  words: AUWord[]
}

export interface AUListItem {
  id: number
  name: string
}

export interface AUListItemDoc extends AUListItem {
  isaudio: boolean
}

export interface AUArchiveSearchResultListItemDoc {
  id: number
  order: number
  documentName: string
  collectionID: number
  isaudio: boolean
  mediaType: string
  resultType: string
  transcriptResults: AUSearchInArchiveResultsItemProps[]
}

export interface AUSearchResultListItemDoc extends AUListItemDoc {
  headlines: string[]
}

//#endregion

export interface DocsJSON {
  title: string
  language: string
  external_id: string
  id: number
  media_file_key: string
  peaks_file_key: string
  file_type: string
  fk_collection: number
}

//#region SearchInCollection Results

export interface FilteredDocsJSON {
  document: SearchDocument
  headlines?: HeadlinesEntity[] | null
}
export interface SearchDocument {
  title: string
  language: string
  external_id: string
  id: number
  audio_file_key: string
  peaks_file_key: string
  file_type: string
}
export interface HeadlinesEntity {
  tokens?: TokensEntity[] | null
}
export interface TokensEntity {
  text: string
  match: boolean
}

export interface AUWordCloudWord {
  word: string
  frequency: number
  recordings: AUChartRecording[]
}

export interface AUStatisticsChartData {
  duration: number
  textLength: number
  numberDocuments: number
  labelledEntities: AULabelledEntityCategory[]
}

export interface AULabelledEntityCategory {
  label: string
  entites: AULabelledEntity[]
}

export interface AULabelledEntity {
  name: string
  frequency: number
  recordings: AUChartRecording[]
}

export interface ChartTooltipData {
  name: string
  frequency: number
}

export interface AUChartRecording {
  title: string
  id: number
}

export interface AUTopicModelItem {
  id: number
  x: number
  y: number
  size: number
  model_name: string
  lable: string
  words_json: AUTopicModelItemWord[]
}

export interface AUTopicModelItemWord {
  word: string
  frequency: number
}

export interface AUExport {
  id: number
  date: string
  wordFileKey?: string
  pdfFileKey?: string
  srtFileKey?: string
  jsonType?: string
  jsonFileKey?: string
}
