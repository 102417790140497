import React, { useState } from "react"
import InputLabel from "@mui/material/InputLabel"
import MenuItem from "@mui/material/MenuItem"
import FormControl from "@mui/material/FormControl"
import Select, { SelectChangeEvent } from "@mui/material/Select"
import { useTranslation } from "next-i18next"
import { Box, Button } from "@mui/material"
import { useRouter } from "next/router"
import { Languages } from "./utils/models"
import { updateMe } from "./utils/api/aurekaAPI"
import { useAuth } from "./context/AuthContext"

export enum LanguageSelectorOrientation {
  HORIZONTAL,
  DROPDOWN,
}

enum LanguagesSelector {
  EN = "EN",
  DE = "DE",
}

export interface AULanguageSelectorProps {
  layout?: LanguageSelectorOrientation
}

const AULanguageSelector = ({
  layout = LanguageSelectorOrientation.DROPDOWN,
}: AULanguageSelectorProps) => {
  const router = useRouter()
  const { locale } = router
  const [language, setLanguage] = useState(locale)
  const { user, updateUser } = useAuth() as any

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault()
    setLanguage(event.target.value)
    router.push(router.asPath, undefined, { locale: event.target.value })
  }

  const handleLanguageChange = (lang: string) => {
    setLanguage(lang)
    // TODO: update Language of user if authenticated
    let userData = {
      language: lang,
    }
    if (user) {
      updateMe(userData)
        .then(() => {
          updateUser()
            .then(() => {
              router.push(router.asPath, undefined, { locale: lang })
            })
            .catch((error) => {
              console.log(error)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      router.push(router.asPath, undefined, { locale: lang })
    }
  }

  return (
    <FormControl
      variant="standard"
      sx={{ width: "fit-content", marginRight: "10px" }}
    >
      {layout == LanguageSelectorOrientation.DROPDOWN && (
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={language}
          onChange={handleChange}
        >
          <MenuItem value={Languages.DE}>{LanguagesSelector.DE}</MenuItem>
          <MenuItem value={Languages.ENGB}>{LanguagesSelector.EN}</MenuItem>
        </Select>
      )}
      {layout == LanguageSelectorOrientation.HORIZONTAL && (
        <div
          style={{
            color: "black",
          }}
        >
          <Button
            disabled={language == Languages.DE}
            color="inherit"
            variant="text"
            sx={{
              minWidth: "20px",
              padding: 0,
            }}
            onClick={() => {
              handleLanguageChange(Languages.DE)
            }}
          >
            DE
          </Button>
          <span
            style={{
              padding: "0 10px",
            }}
          >
            |
          </span>
          <Button
            disabled={language == Languages.ENGB}
            color="inherit"
            variant="text"
            sx={{
              minWidth: "20px",
              padding: 0,
            }}
            onClick={() => {
              handleLanguageChange(Languages.ENGB)
            }}
          >
            EN
          </Button>
        </div>
      )}
    </FormControl>
  )
}

export default AULanguageSelector
