import React from "react"
import Head from "next/head"
import { ThemeProvider } from "@mui/material/styles"
import CssBaseline from "@mui/material/CssBaseline"
import { CacheProvider } from "@emotion/react"
import theme from "../src/theme"
import createEmotionCache from "../src/createEmotionCache"
import FixedHeader from "../src/Header/fixedHeader"
import { LicenseInfo } from "@mui/x-data-grid-pro"
import { appWithTranslation } from "next-i18next"
import { AuthProvider } from "../src/context/AuthContext"
import { HistoryProvider } from "../src/context/HistoryContext"
import { CreateUpdateArchiveContextProvider } from "../src/context/CreateUpdateArchiveContext"
import { PlayerContextProvider } from "../src/context/PlayerContext"

const appVersion = require("./../package.json").version

import "../styles/globals.css"
import "@fontsource/roboto"
import "@fontsource/libre-franklin"
import { Grid } from "@mui/material"
import { useRouter } from "next/router"
import { useEffect } from "react"
// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

import "../styles/breadcrumbs.css"
import { isAuthenticated } from "../src/utils/api/auth"

export function getStaticProps() {
  return {
    // returns the default 404 page with a status code of 404
    notFound: true,
  }
}

const MyApp = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
  const router = useRouter()

  useEffect(() => {
    LicenseInfo.setLicenseKey(
      "d062d7e5e1630eb0c848d7f51f9126caT1JERVI6MzExNzIsRVhQSVJZPTE2NjYyODA1NzQwMDAsS0VZVkVSU0lPTj0x"
    )

    /*if (!isAuthenticated()) {
      router.push("/login")
    }*/
  }, [])

  return (
    <AuthProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <title>aureka.ai</title>
          <meta name="viewport" content="initial-scale=1, width=device-width" />
          <base href="/" />
          <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href="/assets/Logos/Symbol-white.png"
          />
          <meta name="description" content="Generated by create next app" />
        </Head>

        <ThemeProvider theme={theme}>
          <HistoryProvider>
            <CssBaseline />
            <PlayerContextProvider>
              {router.route.startsWith("/archives") ||
              router.route.startsWith("/account") ? (
                <FixedHeader />
              ) : (
                ""
              )}

              <Grid
                sx={{
                  height:
                    !router.route.startsWith("/archives") &&
                    !router.route.startsWith("/account")
                      ? "100%"
                      : "initial",
                }}
                container
                direction="row"
                justifyContent="start"
                alignItems="start"
              >
                {router.pathname.endsWith("/add") ? (
                  <CreateUpdateArchiveContextProvider>
                    <Grid
                      item
                      xs={12}
                      style={{ height: "100%", minWidth: "550px" }}
                    >
                      <Component {...pageProps} />
                    </Grid>
                  </CreateUpdateArchiveContextProvider>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{ height: "100%", minWidth: "550px" }}
                  >
                    <Component {...pageProps} />
                  </Grid>
                )}
              </Grid>
            </PlayerContextProvider>
          </HistoryProvider>
        </ThemeProvider>
      </CacheProvider>
    </AuthProvider>
  )
}

export default appWithTranslation(MyApp)
