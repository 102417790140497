import { useRouter } from "next/router"
import React, { createContext, useContext, useEffect, useState } from "react"
import {
  AUArchive,
  AUCollection,
  AUDocument,
  AUSubscription,
  AUSubscriptionPlanTypes,
  AUUser,
  Languages,
} from "../utils/models"
import { useHistory } from "../context/HistoryContext"

const CreateUpdateArchiveContext = createContext({})

export const CreateUpdateArchiveContextProvider = ({ children }) => {
  const router = useRouter()
  const [archive, setArchive] = useState<AUArchive | null>(null)
  const [collection, setCollection] = useState<AUCollection | null>(null)
  const [docs, setDocs] = useState<AUDocument[] | null>(null)
  const { history } = useHistory()

  useEffect(() => {
    if (history.length == 0) {
      const { pathname, query } = router
      const params = new URLSearchParams(query as any)
      params.delete("step")
      router.push({ pathname, query: params.toString() })
    }
    resetCreateProcess()
  }, [])

  const setCurrentArchive = (archive: AUArchive) => {
    sessionStorage.setItem("current_created_archive", JSON.stringify(archive))
    setArchive(archive)
  }

  const setCurrentCollection = (collection: AUCollection) => {
    sessionStorage.setItem(
      "current_created_collection",
      JSON.stringify(collection)
    )
    setCollection(collection)
  }

  const resetCreateProcess = () => {
    sessionStorage.removeItem("current_created_archive")
    sessionStorage.removeItem("current_created_collection")
    setArchive(null)
    setCollection(null)
    setDocs(null)
  }

  return (
    <CreateUpdateArchiveContext.Provider
      value={{ archive, collection, setCurrentArchive, setCurrentCollection }}
    >
      {children}
    </CreateUpdateArchiveContext.Provider>
  )
}

export const useCreateUpdateArchiveContext = () =>
  useContext(CreateUpdateArchiveContext)
