import React, { useEffect, useState } from "react"
import AppBar from "@mui/material/AppBar"
import Toolbar from "@mui/material/Toolbar"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import MenuIcon from "@mui/icons-material/Menu"
import Logout from "@mui/icons-material/Logout"
import { useRouter } from "next/router"
import {
  Box,
  CssBaseline,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from "@mui/material"
import Image from "next/image"
import HeaderNavigation from "./headerNavigation"
import BrandProgress from "../BrandProgress"
import AULangugageSelector, {
  LanguageSelectorOrientation,
} from "../AULanguageSelector"
import { useTranslation } from "next-i18next"
import { Container } from "@mui/system"
import { useAuth } from "../context/AuthContext"
import { AUSubscriptionPlanTypes, SubscriptionStatus } from "../utils/models"
import * as Sentry from "@sentry/browser"

const FixedHeader = () => {
  const router = useRouter()
  const [anchorEl, setAnchorEl] = useState(null)
  const isMenuOpen = Boolean(anchorEl)
  const [inProgress, setInProgress] = useState(false)
  const { user, userLoading, subscription, handleLogout } = useAuth()

  const { t } = useTranslation()

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const performLogout = () => {
    handleLogout().then(() => {
      router.push("/login")
    })
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const menuId = "primary-search-account-menu"

  useEffect(() => {
    const handleRouteChangeStart = (url, { shallow }) => {
      if (typeof setInProgress === "function") {
        setInProgress(true)
      }
    }

    const handleRouteChangeCompleteOrError = (url, { shallow }) => {
      if (typeof setInProgress === "function") {
        setInProgress(false)
      }
    }

    router.events.on("routeChangeStart", handleRouteChangeStart)

    router.events.on("routeChangeComplete", handleRouteChangeCompleteOrError)
    router.events.on("routeChangeError", handleRouteChangeCompleteOrError)

    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart)
      router.events.off("routeChangeComplete", handleRouteChangeCompleteOrError)
      router.events.off("routeChangeError", handleRouteChangeCompleteOrError)
    }
  }, [])

  return (
    <>
      <CssBaseline />
      <div style={{ flexGrow: 1 }}>
        <AppBar
          sx={{
            backgroundColor: "white !important",
            boxShadow: "none !important",
            padding: 0,
          }}
          position="fixed"
        >
          <Toolbar
            disableGutters={false}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              borderBottom: "1px solid lavender",
              minHeight: "35px",
              padding: 0,
            }}
          >
            <Container maxWidth="xl">
              <Grid container spacing={1} direction={"row"}>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={2}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      cursor: "pointer",
                      marginTop: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Image
                      onClick={() => {
                        router.push("/archives")
                      }}
                      width={125}
                      height={35}
                      objectFit={"contain"}
                      src="/assets/Logos/Final-Logo-Transparent.png"
                      variant="logo"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={11}
                  sm={11}
                  md={8}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {!userLoading &&
                  user &&
                  !inProgress &&
                  subscription.status == SubscriptionStatus.SUBSCRIBED ? (
                    <HeaderNavigation />
                  ) : (
                    <BrandProgress />
                  )}
                </Grid>
                <Grid item xs={1} sm={1} md={2}>
                  {" "}
                  {/*(!userLoading && !user) ? (
                  <Button
                    onClick={() => {
                      router.push("/login")
                    }}
                    color="primary"
                  >
                    {t("login")}
                  </Button>
                ) : (
                  ""
                )*/}
                  {!userLoading &&
                    user &&
                    subscription.status == SubscriptionStatus.SUBSCRIBED && (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "end",
                          flexDirection: "row",
                          height: "100%",
                        }}
                      >
                        {!router.pathname.startsWith("/account") && (
                          <Box
                            sx={{
                              flexDirection: "row",
                              justifyContent: "end",
                              alignItems: "center",
                              margin: "0 10px",
                              minWidth: "400px",
                              color: "black",
                              display: {
                                xs: "none",
                                sm: "none",
                                md:
                                  router.pathname == "/archives" ||
                                  router.pathname == "/archives/add"
                                    ? "flex"
                                    : "none",
                                lg: "flex",
                              },
                            }}
                          >
                            <Typography
                              sx={{
                                textAlign: "end",
                                fontSize: "0.75rem",
                                marginRight: 1,
                              }}
                              variant="caption"
                            >
                              {t("hint_transcribe_more")}
                            </Typography>
                            {subscription.type ==
                              AUSubscriptionPlanTypes.FREE_TRIAL && (
                              <Button
                                sx={{
                                  minWidth: "150px",
                                }}
                                size="small"
                                variant="outlined"
                                onClick={() =>
                                  router.push("/account?section=subscription")
                                }
                              >
                                <Typography fontSize="0.7rem">
                                  {t("upgrade_to_pro")}
                                </Typography>
                              </Button>
                            )}
                            {(subscription.type ==
                              AUSubscriptionPlanTypes.PRO_PLAN_MONTHLY ||
                              subscription.type ==
                                AUSubscriptionPlanTypes.PRO_PLAN_YEARLY) && (
                              <Button
                                sx={{ minWidth: "150px" }}
                                size="small"
                                variant="outlined"
                                onClick={() =>
                                  router.push("/account?section=subscription")
                                }
                              >
                                <Typography fontSize="0.7rem">
                                  {t("buy_more_hours")}
                                </Typography>
                              </Button>
                            )}
                          </Box>
                        )}
                        <IconButton
                          aria-label="account of current user"
                          aria-controls="menu-appbar"
                          aria-haspopup="true"
                          onClick={handleMenu}
                          color="primary"
                          size="large"
                          sx={{ marginLeft: 3 }}
                        >
                          <MenuIcon size={"large"} />
                        </IconButton>
                        <Menu
                          id="menu-appbar"
                          anchorEl={anchorEl}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          keepMounted
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "right",
                          }}
                          open={isMenuOpen}
                          onClose={handleClose}
                        >
                          {/*<MenuItem disabled onClick={handleClose}>
                        {email}
                      </MenuItem>
                      <MenuItem disabled onClick={handleClose}>
                        Version {appVersion}
                    </MenuItem>*/}
                          {process.env.NEXT_PUBLIC_FRONTEND_ENV !==
                            "production" && (
                            <MenuItem
                              onClick={() => {
                                try {
                                  throw new Error("Sentry Frontend Error")
                                } catch (err) {
                                  Sentry.captureException(err)
                                }
                              }}
                            >
                              Simulate crash
                            </MenuItem>
                          )}
                          <MenuItem
                            sx={{
                              display: {
                                xs: "inline-block",
                                sm: "inline-block",
                                md:
                                  router.pathname != "/archives" &&
                                  router.pathname != "/archives/add"
                                    ? "flex"
                                    : "none",
                                lg: "none",
                              },
                            }}
                          >
                            {subscription.type ==
                              AUSubscriptionPlanTypes.FREE_TRIAL && (
                              <Button
                                sx={{ minWidth: "150px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  router.push("/account?section=subscription")
                                }
                              >
                                {t("upgrade_to_pro")}
                              </Button>
                            )}
                            {(subscription.type ==
                              AUSubscriptionPlanTypes.PRO_PLAN_MONTHLY ||
                              subscription.type ==
                                AUSubscriptionPlanTypes.PRO_PLAN_YEARLY) && (
                              <Button
                                sx={{ minWidth: "150px" }}
                                size="small"
                                variant="contained"
                                onClick={() =>
                                  router.push("/account?section=subscription")
                                }
                              >
                                {t("buy_more_hours")}
                              </Button>
                            )}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose()
                              router.push("/account?section=personal_data")
                            }}
                          >
                            {t("user_settings")}
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              handleClose()
                              router.push("/account?section=subscription")
                            }}
                          >
                            {t("subscription")}
                          </MenuItem>
                          <MenuItem onClick={handleClose}>
                            <AULangugageSelector
                              layout={LanguageSelectorOrientation.HORIZONTAL}
                            />
                          </MenuItem>
                          <Divider />
                          <MenuItem onClick={performLogout}>
                            <ListItemIcon>
                              <Logout color="secondary" fontSize="small" />
                            </ListItemIcon>
                            <ListItemText>{t("logout")}</ListItemText>
                          </MenuItem>
                        </Menu>
                      </div>
                    )}
                </Grid>
              </Grid>
            </Container>
          </Toolbar>
        </AppBar>
        <Toolbar />
        <Toolbar
          sx={{
            display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
          }}
        />
      </div>
    </>
  )
}

export default FixedHeader
