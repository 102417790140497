import React, { createContext, useContext, useEffect, useState } from "react"

export const PlayerContext = createContext({})

export const PlayerContextProvider = ({ children }) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const togglePlayingState = (state) => {
    setIsPlaying(state)
  }

  return (
    <PlayerContext.Provider value={{ isPlaying, togglePlayingState }}>
      {children}
    </PlayerContext.Provider>
  )
}

export const usePlayerContext = () => useContext(PlayerContext)
