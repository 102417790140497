import decodeJwt from "jwt-decode"

export const token = () => {
  const token = localStorage.getItem("token")
  if (!token) {
    return "no token"
  }
  return token
}

export const uid = () => {
  const uid = localStorage.getItem("uid")
  if (!uid) {
    return "no user"
  }
  return uid
}

export const isAuthenticated = () => {
  const token = localStorage.getItem("token")
  if (!token) {
    return false
  }
  return true
}

/**
 * Login to backend and store JSON web token on success
 *
 * @param email
 * @param password
 * @returns JSON data containing access token on success
 * @throws Error on http errors or failed attempts
 */
export const login = async (email, password) => {
  // Assert email or password is not empty
  if (!(email.length > 0) || !(password.length > 0)) {
    throw new Error("Email or password was not provided")
  }
  const formData = new FormData()
  // OAuth2 expects form data, not JSON data
  formData.append("username", email)
  formData.append("password", password)

  const request = new Request("/api/v1/login/access-token", {
    method: "POST",
    body: formData,
    mode: "no-cors",
  })

  const response = await fetch(request)

  if (response.status === 500) {
    throw new Error("Internal server error")
  }

  const data = await response.json()

  if (response.status > 400 && response.status < 500) {
    if (data.detail) {
      throw data.detail
    }
    throw data
  }

  return data
}

export const logout = () => {
  localStorage.removeItem("token")
}
