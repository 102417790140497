import axios from "axios"
import { AurekaError } from "../models"

let stripeFormatedURLDevelopment =
  process.env.NEXT_PUBLIC_FRONTEND_ENV !== "local" ? ":3000" : ":8888"

let apiHeaders = {
  Accept: "application/json",
  charset: "utf-8",
  "Content-Type": "application/json",
  authorization: "",
}

const getHeaders = () => {
  apiHeaders = {
    ...apiHeaders,
    authorization: "Bearer " + localStorage.getItem("token"),
  }

  return apiHeaders
}
const requestHandler = (request) => {
  return request
}

const responseHandler = (response) => {
  return response
}

const errorHandler = (error) => {
  if (error.response.status === 402) {
    window.location.href = "/subscription-cancelled"
  } else if (error.response.status === 414) {
    window.location.href = "/subscription-paused"
  } else if (error.response.status === 415) {
    window.location.href = "/payment-failed"
  }

  return Promise.reject(error)
}

const getInstance = () => {
  let instance = axios.create({
    timeout: 0,
    headers: getHeaders(),
  })

  instance.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
  )

  instance.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
  )
  return instance
}

export const getErrorInfo = (error, t): AurekaError => {
  let responseCode = 200
  //const { t } = useTranslation()

  if (error.response) {
    responseCode = error.response.status
  } else if (error.request) {
    responseCode = error.request.status
  }

  let errorString =
    t("error_intro") + " " + t("" + responseCode) + " " + t("error_outro")
  var errorTitle = responseCode + ""
  if (
    error.response &&
    error.response.data.detail &&
    error.response.data.detail.length > 0
  ) {
    errorTitle = error.response.data.detail[0].msg
    return { title: errorTitle, message: errorString }
  }

  if (error.response === undefined) {
    errorTitle = "Frontend Error"
    errorString = "" + error
  }

  return { title: errorTitle, message: errorString }
}

export async function getAuthToken(email, password) {
  const formData = new FormData()
  // OAuth2 expects form data, not JSON data
  formData.append("username", email)
  formData.append("password", password)
  return getInstance().post("/api/v1/login/access-token", formData)
}

export async function getMe() {
  return getInstance().get("/api/v1/users/me")
}

export async function updateMe(userData) {
  return getInstance().put("/api/v1/users/me", userData)
}

export async function resendVerifyTokenEmail(email) {
  return getInstance().post(`/api/v1/login/token-resend/` + email)
}

export async function verifyUser() {
  return getInstance().post(`/api/v1/login/verify`)
}

export async function sendPasswordRecoveryMail(email) {
  return getInstance().post(`/api/v1/password-recovery/` + email)
}

export async function resetPassword(resetPasswordToken, password) {
  let data = {
    token: resetPasswordToken,
    new_password: password,
  }
  return getInstance().post(`/api/v1/reset-password`, data)
}

export async function createFreeTrialUser(
  user,
  language,
  successUrl,
  cancelUrl
) {
  let userData = {
    email: user.email,
    full_name: user.fullname,
    password: user.password,
    newsletter: user.subscribeNewsletter,
    language: language,
  }
  return getInstance().post(
    `/api/v1/stripe/users/free?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl,
    userData
  )
}

export async function createProMonthUser(
  user,
  language,
  successUrl,
  cancelUrl
) {
  let userData = {
    email: user.email,
    full_name: user.fullname,
    password: user.password,
    newsletter: user.subscribeNewsletter,
    language: language,
  }
  return getInstance().post(
    `/api/v1/stripe/users/pro/month?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl,
    userData
  )
}

export async function createProYearUser(user, language, successUrl, cancelUrl) {
  let userData = {
    email: user.email,
    full_name: user.fullname,
    password: user.password,
    newsletter: user.subscribeNewsletter,
    language: language,
  }
  return getInstance().post(
    `/api/v1/stripe/users/pro/year?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl,
    userData
  )
}

export async function manageStripeSubscription(returnUrl) {
  return getInstance().post(
    `/api/v1/stripe/users/billing?return_url=` +
      stripeFormatedURLDevelopment +
      returnUrl
  )
}

export async function buyExtraHoursPortal(successUrl, cancelUrl) {
  return getInstance().post(
    `/api/v1/stripe/users/add/hours?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl
  )
}

export async function getProMonthSubscription(successUrl, cancelUrl) {
  return getInstance().post(
    `/api/v1/stripe/users/pro/month/me?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl
  )
}

export async function getProYearSubscription(successUrl, cancelUrl) {
  return getInstance().post(
    `/api/v1/stripe/users/pro/year/me?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl
  )
}

export async function getFreeTrialSubscription(successUrl, cancelUrl) {
  return getInstance().post(
    `/api/v1/stripe/users/free/me?success_path=` +
      stripeFormatedURLDevelopment +
      successUrl +
      `&cancel_path=` +
      stripeFormatedURLDevelopment +
      cancelUrl
  )
}

export async function getCancellingStatus() {
  return getInstance().get("/api/v1/stripe/users/sub/canceling")
}

export async function getArchives() {
  return getInstance().get("/api/v1/archives")
}

export async function getArchive(archiveID) {
  return getInstance().get("/api/v1/archives/" + archiveID)
}

export async function updateArchiveName(archiveID, archiveName) {
  let updateData = {
    archive_in: {
      name: archiveName,
    },
  }
  return getInstance().put("/api/v1/archives/" + archiveID, updateData)
}

export async function createArchive(
  archiveName,
  archiveContactName,
  archiveContactEmail
) {
  let createArchiveData = {
    archive_in: {
      name: archiveName,
    },
    contact_in: {
      name: archiveContactName,
      mail: archiveContactEmail,
    },
  }
  return getInstance().post("/api/v1/archives", createArchiveData)
}

export async function assignArchiveToUser(userID, archiveID) {
  return getInstance().put("api/v1/users/" + userID + "/archives/" + archiveID)
}

export async function updateArchive(
  archiveID,
  archiveName,
  archiveContactName,
  archiveContactEmail
) {
  let updateArchiveData = {
    archive_in: {
      name: archiveName,
    },
    contact_in: {
      name: archiveContactName,
      mail: archiveContactEmail,
    },
  }
  return getInstance().put("/api/v1/archives/" + archiveID, updateArchiveData)
}

export async function deleteArchiveById(archiveID) {
  return getInstance().delete("/api/v1/archives/" + archiveID)
}

export async function getCollections(archiveID) {
  return getInstance().get(`/api/v1/archives/` + archiveID + "/collections")
}

export async function getCollection(archiveID, collectionID) {
  return getInstance().get(
    `/api/v1/archives/` + archiveID + "/collections/" + collectionID
  )
}

export async function updateCollection(
  archiveID,
  collectionID,
  collectionName
) {
  const collData = {
    name: collectionName,
  }
  return getInstance().put(
    `/api/v1/archives/` + archiveID + `/collections/` + collectionID,
    collData
  )
}

export async function createCollection(archiveID, collectionName) {
  const collData = {
    name: collectionName,
  }
  return getInstance().post(
    `/api/v1/archives/` + archiveID + `/collections`,
    collData
  )
}

export async function deleteCollectionById(archiveID, collectionID) {
  return getInstance().delete(
    `/api/v1/archives/` +
      archiveID +
      "/collections?collection_id=" +
      collectionID
  )
}

export async function getDocs(collectionID) {
  return getInstance().get(
    `/api/v1/colls/` + collectionID + "/?skip=0&limit=100"
  )
}

export async function createDocument(
  collectionID,
  title,
  language,
  externalId
) {
  let docData = {
    title: title,
    language: language,
    external_id: externalId,
  }
  return getInstance().post(`/api/v1/colls/` + collectionID, docData)
}

export async function uploadTranscribeDocs(collectionID, files, docs) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append("files", file)
  })
  formData.append("doc_ids_with_name", JSON.stringify(docs))
  return getInstance().post(
    `/api/v1/colls/` + collectionID + `/docs/upload_and_preprocess`,
    formData
  )
}

export async function getDoc(collectionID, docID) {
  return getInstance().get(`/api/v1/colls/` + collectionID + "/docs/" + docID)
}

export async function getTranscriptionSupportedLanguages() {
  return getInstance().get("api/v1/colls/languages?skip=0&limit=100")
}

export async function getTranscript(collectionID, docID) {
  return getInstance().get(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/words"
  )
}

export async function updateDocTitle(collectionID, docID, newTitle) {
  let data = {
    title: newTitle,
  }
  return getInstance().put(
    `/api/v1/colls/` + collectionID + "/docs/" + docID,
    data
  )
}

export async function deleteDoc(collectionID, docID) {
  return getInstance().delete(
    "/api/v1/colls/" + collectionID + "/docs/" + docID
  )
}

export async function getDocStatus() {
  return getInstance().get("/api/v1/users/status/docs")
}

export async function updateMetadataItem(collectionID, docID, itemID, item) {
  const data = {
    item,
  }

  debugger

  return getInstance().put(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/meta/" + itemID,
    item
  )
}

export async function updateSpeakerForTime(
  speakerID,
  startTime,
  endTime,
  collectionID,
  docID,
  paragraphID
) {
  let data = {
    start: startTime,
    end: endTime,
  }

  return getInstance().patch(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      docID +
      "/para/" +
      paragraphID +
      "/speakers/" +
      speakerID +
      "/time",
    data
  )
}

export async function updateSpeakerForParagraph(
  speakerID,
  collectionID,
  docID,
  paragraphID
) {
  return getInstance().patch(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      docID +
      "/para/" +
      paragraphID +
      "/speakers/" +
      speakerID
  )
}

export async function updateTranscriptForTime(
  newText,
  startTime,
  endTime,
  collectionID,
  docID
) {
  let data = {
    start_time: startTime,
    end_time: endTime,
    text: newText,
  }
  return getInstance().post(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/words",
    data
  )
}

export async function updateParagraph(
  newText,
  collectionID,
  docID,
  paragraphID
) {
  let data = {
    text: newText,
  }
  return getInstance().post(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      docID +
      "/para/" +
      paragraphID +
      "/words",
    data
  )
}

export async function createTranscriptSpeaker(name, collectionID, docID) {
  let data = {
    name,
  }
  return getInstance().post(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/speakers",
    data
  )
}

export async function updateTranscriptSpeakerName(
  name,
  collectionID,
  docID,
  speakerID
) {
  let data = {
    name,
    id: speakerID,
  }
  return getInstance().put(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      docID +
      "/speakers/" +
      speakerID,
    data
  )
}

export async function deleteMetadataItem(itemID, collectionID, docID) {
  return getInstance().delete(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/meta/" + itemID
  )
}

export async function createMetadataItem(collectionID, docID, data) {
  return getInstance().post(
    "/api/v1/colls/" + collectionID + "/docs/" + docID + "/meta",
    data
  )
}

export async function getMetaItems(collectionID, documentID) {
  return getInstance().get(
    "/api/v1/colls/" + collectionID + "/docs/" + documentID + "/meta"
  )
}

export async function getAnnotations(collectionID, documentID) {
  return getInstance().get(
    "/api/v1/colls/" + collectionID + "/docs/" + documentID + "/annot"
  )
}

export async function getCollectionAnnotations(collectionID) {
  return getInstance().get("/api/v1/visuals/" + collectionID + "/annotplot")
}

export async function deleteAnnotation(collectionID, documentID, annotationID) {
  return getInstance().delete(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      documentID +
      "/annot/" +
      annotationID
  )
}

export async function updateAnnotation(
  collectionID,
  documentID,
  annotationID,
  data
) {
  return getInstance().put(
    "/api/v1/colls/" +
      collectionID +
      "/docs/" +
      documentID +
      "/annot/" +
      annotationID,
    data
  )
}
export async function createAnnotation(collectionID, documentID, data) {
  return getInstance().post(
    "/api/v1/colls/" + collectionID + "/docs/" + documentID + "/annot",
    data
  )
}

export async function getMediaPeaksFromURL(peaksURL) {
  return getInstance().get(peaksURL)
}

export async function getMediaPeaks(collectionID, documentID) {
  return getInstance().get(
    "/api/v1/colls/" + collectionID + "/docs/" + documentID + "/peaks"
  )
}

export async function getMediaFileFromURL(streamURL) {
  return getInstance().get(streamURL)
}

export async function getMediaFile(collectionID, documentID) {
  return getInstance().get(
    "/api/v1/colls/" + collectionID + "/docs/" + documentID + "/file"
  )
}

export async function getSearchResults(collectionID, query) {
  ///api/v1/filter/collection/1/docs?filter=tesdt&skip=0&limit=100'
  return getInstance().get(
    "/api/v1/filter/collection/" +
      collectionID +
      "/docs?filter=" +
      query +
      "&skip=0&limit=100"
  )
}
export async function getArchiveSearchResults(archiveID, query) {
  return getInstance().get(
    "/api/v1/filter/archive/" + archiveID + "?filter=" + query
  )
}

// Chart endpoints
export async function generateWordCloud(collectionID, wordThreshold = 50) {
  return getInstance().post(
    "/api/v1/visuals/" +
      collectionID +
      "/wordcloud/?word_threshold=" +
      wordThreshold
  )
}

export async function generateNERStatistics(collectionID) {
  return getInstance().post("/api/v1/visuals/" + collectionID + "/stats")
}

export async function getNERStatsticsData(collectionID) {
  return getInstance().get("/api/v1/visuals/" + collectionID + "/stats")
}

export async function generateNERStatisitcs(collectionID) {
  return getInstance().post("/api/v1/visuals/" + collectionID + "/stats")
}

export async function getWordCloudData(collectionID) {
  return getInstance().get("/api/v1/visuals/" + collectionID + "/wordcloud")
}

export async function getTaskStatus(taskID) {
  return getInstance().get("api/v1/users/status/job/" + taskID)
}

export async function getCollectionStatus(collectionID) {
  return getInstance().get("api/v1/visuals/" + collectionID + "/status")
}

export async function updateCollectionStatus(collectionID, status) {
  return getInstance().put("api/v1/visuals/" + collectionID + "/status", status)
}

export async function generateTopicModel(collectionID, topWords = 10) {
  return getInstance().post(
    "api/v1/visuals/" + collectionID + "/topicmodel?top_n_words=" + topWords
  )
}

export async function getTopicModel(collectionID, topWords = 10) {
  return getInstance().get(
    "api/v1/visuals/" + collectionID + "/topicmodel?top_n_words=" + topWords
  )
}

export async function renameTopic(collectionID, topicID, label) {
  return getInstance().put(
    "api/v1/visuals/" + collectionID + "/topicmodel/" + topicID,
    label
  )
}

export async function createExport(collectionID, documentID) {
  return getInstance().post(
    "api/v1/export/" + collectionID + "/docs/" + documentID
  )
}

export async function getExports(collectionID, documentID) {
  return getInstance().get(
    "api/v1/export/" + collectionID + "/docs/" + documentID
  )
}

export async function deleteExport(exportID) {
  return getInstance().delete("api/v1/export/" + exportID)
}
