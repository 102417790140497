import { useRouter } from "next/router"
import { createContext, useContext, useEffect, useState } from "react"
import { getAuthToken, getMe } from "../utils/api/aurekaAPI"
import { isAuthenticated } from "../utils/api/auth"
import {
  AUBackendSubscriptionPlanTypes,
  AUSubscription,
  AUSubscriptionPlanTypes,
  AUUser,
  Languages,
  SubscriptionStatus,
  UserRoles,
} from "../utils/models"

const AuthContext = createContext({})

export const AuthProvider = ({ children }) => {
  const router = useRouter()
  const [user, setUser] = useState<AUUser>(null)
  const [subscription, setSubscription] = useState<AUSubscription>(null)
  const [userLoading, setUserLoading] = useState(true)

  useEffect(() => {
    setUserLoading(true)
    if (isAuthenticated()) {
      getMe()
        .then((res: any) => {
          const user = {
            email: res.data.user.email,
            isActive: res.data.user.is_active,
            role: res.data.user.role as UserRoles,
            fullName: res.data.user.full_name,
            id: res.data.user.id,
            isVerified: res.data.user.is_verified,
            newsletter: res.data.user.newsletter,
            language: res.data.user.language as Languages,
          }
          setUser(user)

          if (res.data.sub) {
            const subscription = {
              type: getSubType(res.data.sub.type),
              period: res.data.sub.period,
              subscriptionAvaiableMinutes: res.data.sub.sub_avail_minutes,
              extraAvaiableMinutes: res.data.sub.extra_avail_minutes,
              usedMinutes: res.data.sub.used_minutes,
              paid: res.data.sub.paid,
              status: res.data.sub.status as SubscriptionStatus,
              renewalDate: res.data.sub.renewal_date,
            }
            setSubscription(subscription)
          } else {
            setSubscription(null)
          }
          setUserLoading(false)
        })
        .catch(function (error) {
          if (error.response.status === 403) {
            router.push("/login")
          } else {
            console.log(error)
          }
        })
    } else {
      if (router.pathname != "/reset" && router.pathname != "/register") {
        router.push("/login")
      }
    }
  }, [])

  const handleLogin = async (email, password) => {
    try {
      setUserLoading(true)
      const auth = await getAuthToken(email, password)
      localStorage.setItem("token", auth.data["access_token"])
      const meUser = (await getMe()) as any
      const user = {
        email: meUser.data.user.email,
        isActive: meUser.data.user.is_active,
        role: meUser.data.user.role as UserRoles,
        fullName: meUser.data.user.full_name,
        id: meUser.data.user.id,
        isVerified: meUser.data.user.is_verified,
        newsletter: meUser.data.user.newsletter,
        language: meUser.data.user.language as Languages,
      }
      setUser(user)
      let subscription: AUSubscription
      if (meUser.data.sub) {
        subscription = {
          type: getSubType(meUser.data.sub.type),
          period: meUser.data.sub.period,
          subscriptionAvaiableMinutes: meUser.data.sub.sub_avail_minutes,
          extraAvaiableMinutes: meUser.data.sub.extra_avail_minutes,
          usedMinutes: meUser.data.sub.used_minutes,
          paid: meUser.data.sub.paid,
          status: meUser.data.sub.status as SubscriptionStatus,
          renewalDate: meUser.data.sub.renewal_date,
        }
        setSubscription(subscription)
      } else {
        setSubscription(null)
      }
      setSubscription(subscription)
      setUserLoading(false)
      return { user: user, subscription: subscription }
    } catch (err) {
      throw err
    }
  }

  const handleLogout = async () => {
    localStorage.removeItem("token")
    setUser(null)
  }

  const updateUser = async () => {
    try {
      setUserLoading(true)
      const meUser = (await getMe()) as any
      const user = {
        email: meUser.data.user.email,
        isActive: meUser.data.user.is_active,
        role: meUser.data.user.role as UserRoles,
        fullName: meUser.data.user.full_name,
        id: meUser.data.user.id,
        isVerified: meUser.data.user.is_verified,
        newsletter: meUser.data.user.newsletter,
        language: meUser.data.user.language as Languages,
      }
      setUser(user)
      let subscription: AUSubscription
      if (meUser.data.sub) {
        subscription = {
          type: getSubType(meUser.data.sub.type),
          period: meUser.data.sub.period,
          subscriptionAvaiableMinutes: meUser.data.sub.sub_avail_minutes,
          extraAvaiableMinutes: meUser.data.sub.extra_avail_minutes,
          usedMinutes: meUser.data.sub.used_minutes,
          paid: meUser.data.sub.paid,
          status: meUser.data.sub.status as SubscriptionStatus,
          renewalDate: meUser.data.sub.renewal_date,
        }
        setSubscription(subscription)
      } else {
        setSubscription(null)
      }
      setSubscription(subscription)
      setUserLoading(false)
      return { user: user, subscription: subscription }
    } catch (err) {
      throw err
    }
  }

  const getSubType = (subType) => {
    let type: AUSubscriptionPlanTypes
    switch (subType) {
      case AUBackendSubscriptionPlanTypes.FREE_TRIAL:
        type = AUSubscriptionPlanTypes.FREE_TRIAL
        break
      case AUBackendSubscriptionPlanTypes.PRO_PLAN_MONTHLY:
        type = AUSubscriptionPlanTypes.PRO_PLAN_MONTHLY
        break
      case AUBackendSubscriptionPlanTypes.PRO_PLAN_YEARLY:
        type = AUSubscriptionPlanTypes.PRO_PLAN_YEARLY
        break
    }
    return type
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated: !!user,
        user,
        subscription,
        handleLogin,
        userLoading,
        handleLogout,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = () => useContext(AuthContext)

export const ProtectedPage = (Page) => {
  const ProtectedPg = () => {
    const [isAuth, setIsAuth] = useState(false)
    const router = useRouter()
    const { user, userLoading, subscription } = useAuth() as any
    useEffect(() => {
      if (!userLoading) {
        if (isAuthenticated() && user) {
          if (subscription) {
            if (subscription.paid) {
              switch (subscription.status) {
                case SubscriptionStatus.SUBSCRIBED:
                  setIsAuth(true)
                  break
                case SubscriptionStatus.PAUSED:
                  router.push("/subscription-paused")
                  break
                default:
                  router.push("/login")
              }
            } else {
              if (subscription.status === SubscriptionStatus.CANCELLED) {
                router.push("/subscription-cancelled")
              } else {
                router.push("/payment-failed")
              }
            }
          } else {
            router.push("/subscription-not-completed")
          }
        } else {
          router.push("/login")
        }
      }
    }, [userLoading])
    return !!isAuth && !userLoading && user && subscription ? <Page /> : null
  }
  return ProtectedPg
}

export const AuthenticatedSubscriptionResumePage = (Page) => {
  const SubscriptionPausedPage = () => {
    const [isAuth, setIsAuth] = useState(false)
    const router = useRouter()
    const { user, userLoading, subscription } = useAuth() as any
    useEffect(() => {
      if (!userLoading) {
        if (isAuthenticated() && user) {
          if (subscription.status == SubscriptionStatus.PAUSED) {
            setIsAuth(true)
          } else {
            router.push("/archives")
          }
        } else {
          router.push("/login")
        }
      }
    }, [userLoading])
    return !!isAuth && !userLoading && user ? <Page /> : null
  }
  return SubscriptionPausedPage
}

export const AuthenticatedSubscriptionCancelledPage = (Page) => {
  const SubscriptionCancelledPage = () => {
    const [isAuth, setIsAuth] = useState(false)
    const router = useRouter()
    const { user, userLoading, subscription } = useAuth() as any
    useEffect(() => {
      if (!userLoading) {
        if (isAuthenticated() && user) {
          if (subscription.status == SubscriptionStatus.CANCELLED) {
            setIsAuth(true)
          } else {
            router.push("/archives")
          }
        } else {
          router.push("/login")
        }
      }
    }, [userLoading])
    return !!isAuth && !userLoading && user ? <Page /> : null
  }
  return SubscriptionCancelledPage
}

export const AuthenticatedAddSubscriptionPage = (Page) => {
  const SubscriptionNotCompletedPage = () => {
    const [isAuth, setIsAuth] = useState(false)
    const router = useRouter()
    const { user, userLoading, subscription } = useAuth() as any
    useEffect(() => {
      if (!userLoading) {
        if (isAuthenticated() && user) {
          if (!subscription) {
            setIsAuth(true)
          } else {
            router.push("/archives")
          }
        } else {
          router.push("/login")
        }
      }
    }, [userLoading])
    return !!isAuth && !userLoading && user ? <Page /> : null
  }
  return SubscriptionNotCompletedPage
}

export const AuthenticatedPaymentFailedPage = (Page) => {
  const PaymentFailedPage = () => {
    const [isAuth, setIsAuth] = useState(false)
    const router = useRouter()
    const { user, userLoading, subscription } = useAuth() as any
    useEffect(() => {
      if (!userLoading) {
        if (isAuthenticated() && user) {
          if (subscription && !subscription.paid) {
            setIsAuth(true)
          } else {
            router.push("/subscription-not-completed")
          }
        } else {
          router.push("/login")
        }
      }
    }, [userLoading])
    return !!isAuth && !userLoading && user ? <Page /> : null
  }
  return PaymentFailedPage
}
