import { createTheme } from "@mui/material/styles"
import { red } from "@mui/material/colors"
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip"
import { deDE } from "@mui/x-data-grid-pro"
import { deDE as coreDeDE } from "@mui/material/locale"
import type {} from "@mui/x-data-grid-pro/themeAugmentation"
import { tableCellClasses } from "@mui/material"
import { au_border_radius } from "./utils/themeConstants"

let au_accent_color_2 = "#475FD0"
let au_accent_color_1 = "#8166F5"

let au_color_warning = "#F9822D"
let au_color_danger = "#FF0F00"
let au_color_success = "#5EC274"

let au_font_brand = "Libre Franklin"
let au_font_utility = "Roboto"

let au_text_color_subtitle_text = "#2E2554"
let au_text_color_title_text = "#151C3F"
let au_text_color_dense_text = "#20262D"

let au_background_color = "#fff"

// Create a theme instance.
const theme = createTheme(
  {
    typography: {
      htmlFontSize: 16,

      h5: {
        fontSize: "1rem",
        fontWeight: 400,
        fontFamily: "Libre Franklin",
      },

      h3: {
        fontSize: "1.2rem",
        fontWeight: "bold",
        fontFamily: "Libre Franklin",
      },

      h4: {
        fontSize: "0.8rem",
        fontWeight: 600,
        fontFamily: "Libre Franklin",
        margin: 0,
      },
    },

    palette: {
      primary: {
        main: au_accent_color_1,
      },

      secondary: {
        main: au_accent_color_2,
      },

      info: {
        main: "#FFFF",
      },

      warning: {
        main: au_color_warning,
      },

      success: {
        main: au_color_success,
      },

      error: {
        main: red.A400,
      },

      background: {
        default: au_background_color,
      },
    },

    components: {
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            padding: "0 !important",
          },
          input: {
            paddingLeft: "8px",
            color: au_accent_color_1,
          },
          notchedOutline: {
            borderColor: au_accent_color_1,
            borderWidth: "2px",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            fontFamily: "Libre Franklin",
          },
        },
      },

      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            alignItems: "center",
          },
        },
      },

      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontFamily: "Roboto",
            fontWeight: 600,
            color: "white",
            backgroundColor: "#8D75F7",
          },
        },
      },

      MuiDataGrid: {
        styleOverrides: {
          withBorder: {
            borderRight: "2px solid rgb(255 255 255) !important",
          },

          cell: {
            borderBottom: "2px solid rgb(255 255 255) !important",
          },
          root: {
            height: "97%",
            border: "none",
          },
        },
      },
      MuiToolbar: {
        styleOverrides: {
          root: {
            padding: "12px",
          },
        },
      },

      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: "#fff",
          },
        },
      },
    },
  },
  deDE,
  coreDeDE
)

export default theme
